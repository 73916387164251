<template>
    <v-card class="backgroundMap">
        <div class="leftInfo">
            <!-- SECTION: UNIT LIST -->
            <div class="leftColumn">
                <v-card class="unitBox" tile>
                    <v-list-item class="px-1" dense style="height:4vh">
                        <v-list-item-avatar class="mr-1">
                            <v-icon >local_shipping</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{unitLabel}} 
                                <span style="opacity: 0.5">({{unitList.length}})</span>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn small icon @click="expandUnit = !expandUnit"><v-icon>{{expandUnit ? 'expand_less' : 'expand_more'}}</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-card>
                <v-card class="unitList" tile v-if="expandUnit">
                    <div style="display: flex; flex-direction: row; align-items: center; height: 2rem">
                    <v-text-field dense hide-details outlined class="searchUnitList" v-model="search" >
                        <template v-slot:append>
                                <v-icon small>search</v-icon>
                        </template>
                    </v-text-field>
                    <v-btn x-small icon @click="filterCard = !filterCard">
                        <v-icon small>filter_alt</v-icon>
                    </v-btn>
                    </div>
                    <div class="scrollList">
                    <v-list v-if="units.length > 0">
                        <v-list-item dense @click="selectUnit(unit)" v-for="(unit, index) in unitList" :key="index" class="px-1 unitItem">
                            <v-list-item-avatar size="35" class="mr-1">
                                <img v-if="unit?.driver?.user?.avatar" :src="unit.driver.user.avatar" />                              
                                <v-icon v-else>local_shipping</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title style="font-size: x-small">
                                    {{unit.driver.nickname}}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="unit.transportTrailers.length > 0" style="font-size:x-small">
                                    {{ unit.transportTrailers.length }}
                                    {{ unit.transportTrailers[0].type }}
                                    <span v-if="unit.transportTrailers.length > 1">
                                        trailers
                                    </span>
                                    <span v-if="unit.transportTrailers.length === 1">
                                        trailer
                                    </span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    <v-icon>rv_hookup</v-icon> No trailers
                                    connected
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <div v-if="unit.distanceFromEmpty && chosenBooking[0]">
                                        <v-icon small color="green">location_on</v-icon>
                                        {{ unit.distanceFromEmpty }} from empty pickup
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    </div>
                </v-card>
            </div>
            <div class="rightColumn" >
                <!-- SECTION: UNIT INFO -->
                <v-card :class="$store.state.darkMode ? 'unitInfo_dark' : 'unitInfo_light'" tile v-if="viewUnit && expandUnit" flat>
                    <v-card style="height: 10vh; width: 100%; background-color: var(--v-background-base) !important;" flat >
                        <v-btn small style="position: absolute; right: 0.2rem; top: 0.2rem; z-index: 5;" icon @click="viewUnit = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-list-item v-if="selectedUnit" class="pl-2">
                            <v-list-item-avatar size="45" :class="$store.state.darkMode ? 'unitInfo_dark' : 'unitInfo_light'">
                                <img v-if="selectedUnit?.driver?.user?.avatar" :src="selectedUnit.driver.user.avatar" />                              
                                <v-icon v-else >local_shipping</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{selectedUnit.driver.nickname}}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="selectedUnit.transportTrailers.length > 0">
                                    {{ selectedUnit.transportTrailers.length }}
                                    {{ selectedUnit.transportTrailers[0].type }}
                                    <span v-if="selectedUnit.transportTrailers.length > 1">
                                        trailers
                                    </span>
                                    <span v-if="selectedUnit.transportTrailers.length === 1">
                                        trailer
                                    </span>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>
                                    <v-icon>rv_hookup</v-icon> No trailers
                                    connected
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <div v-if="selectedUnit.distanceFromEmpty && chosenBooking[0]">
                                        <v-icon small color="green">location_on</v-icon>
                                        {{ selectedUnit.distanceFromEmpty }} from empty pickup
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <div class="unitTabs">
                            <v-tabs dense grow v-model="activeTab" hide-slider class="tabBackground">
                                <v-tab v-for="(tab, i) in unitTabs" :key="i" :class="activeTab == tab.name ? $store.state.darkMode ? 'pa-0 activeTab dark' : 'pa-0 activeTab light' : 'pa-0 unitTab'" :href="`#${tab.name}`">
                                    <v-icon>{{tab.icon}}</v-icon>
                                </v-tab>
                            </v-tabs>
                        </div>
                    </v-card>
                    <v-tabs-items v-model="activeTab" class="tabItems" >
                        
                        <v-tab-item value="unit">
                            <v-card class="tabContent px-2 pb-2 pt-6" flat>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4 pb-3" >
                                    <v-subheader class="pl-2" style="font-size: 14px; height: 30px">
                                        <!-- <v-icon class="mr-2" small color="secondary"> -->
                                            <!-- person -->
                                        <!-- </v-icon> -->
                                            <b>Driver</b>
                                    </v-subheader>
                                    <v-divider></v-divider>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-subheader style="height: 2rem; opacity: 0.7">Name</v-subheader>
                                            <v-subheader class="subheadContent" v-if="selectedUnit?.driver?.user?.firstname">
                                                {{ selectedUnit.driver.user.firstname }} {{ selectedUnit.driver.user.surname }}
                                            </v-subheader>
                                        </v-col>

                                        <v-col cols="12" sm="6" md="6">
                                            <v-subheader class="pr-2" style="height: 2rem; opacity: 0.7">Phone</v-subheader>
                                            <v-subheader class="subheadContent pr-2" style="height: 1.5rem" v-if="selectedUnit?.driver?.user?.mobileNumber">
                                                {{ formatNumber(selectedUnit.driver.user.mobileNumber) }}
                                            </v-subheader>
                                        </v-col>    
                                    </v-row>
                                </v-card>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4" >
                                    <v-subheader class="pl-2" style="font-size: 14px; height: 30px">
                                        <!-- <v-icon class="mr-2" small color="secondary"> -->
                                            <!-- local_shipping -->
                                        <!-- </v-icon> -->
                                        <b>Vehicle</b>
                                    </v-subheader>
                                    <v-divider></v-divider>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-subheader style="height: 2rem; opacity: 0.7">Registration</v-subheader>
                                            <v-subheader class="subheadContent" v-if="selectedUnit?.transportVehicle?.registration">
                                                {{ selectedUnit.transportVehicle.registration }}
                                            </v-subheader>
                                        </v-col>

                                        <v-col cols="12" sm="6" md="6">
                                            <v-subheader class="pr-2" style="height: 2rem; opacity: 0.7">Make/Model</v-subheader>
                                            <v-subheader class="pr-2 subheadContent"  v-if="selectedUnit?.transportVehicle?.make || selectedUnit?.transportVehicle?.model ">
                                                {{ selectedUnit.transportVehicle.make }} {{ selectedUnit.transportVehicle.model }}
                                            </v-subheader>
                                        </v-col>    
                                        <v-col cols="12" sm="6" md="6" >
                                            <v-subheader style="height: 2rem; opacity: 0.7;">VIN</v-subheader>
                                            <v-subheader class="subheadContent" v-if="selectedUnit?.transportVehicle?.vin">
                                                {{ selectedUnit.transportVehicle.vin }}
                                            </v-subheader>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4" >
                                    <div v-for="(trailer, i) in selectedUnit?.transportTrailers" :key="i">
                                        <v-subheader class="pl-2" style="font-size: 14px; height: 30px">
                                            <!-- <v-icon class="mr-2" small color="secondary"> -->
                                                <!-- rv_hookup -->
                                            <!-- </v-icon> -->
                                                <b>Trailer {{ selectedUnit?.transportTrailers.length > 1 ? i + 1 : '' }}</b>
                                                <v-spacer></v-spacer>
                                                <b>{{ trailer.type }}</b>
                                        </v-subheader>
                                        <v-divider></v-divider>
                                        <v-row no-gutters >
                                            <v-col cols="12" sm="6" md="6">
                                                <v-subheader style="height: 2rem; opacity: 0.7">Registration</v-subheader>
                                                <v-subheader class="subheadContent" v-if="trailer.registration">
                                                    {{ trailer.registration }}
                                                </v-subheader>
                                            </v-col>

                                            <v-col cols="12" sm="6" md="6">
                                                <v-subheader class="pr-2" style="height: 2rem; opacity: 0.7">Make/Model</v-subheader>
                                                <v-subheader class="pr-2 subheadContent"  v-if="trailer.make || trailer.model ">
                                                    {{ trailer.make }} {{ trailer.model }}
                                                </v-subheader>
                                            </v-col>    
                                            <v-col cols="12" sm="6" md="6" >
                                                <v-subheader style="height: 2rem; opacity: 0.7;">VIN</v-subheader>
                                                <v-subheader class="subheadContent" v-if="trailer.vin">
                                                    {{ trailer.vin }}
                                                </v-subheader>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4" >
                                    <v-subheader class="pl-2" style="font-size: 14px; height: 30px">
                                        <b>Equipment</b>
                                    </v-subheader>
                                    <v-divider></v-divider>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-subheader class="pl-2" style="font-size: 14px; height: 30px">
                                                <b>Genset</b>
                                            </v-subheader>
                                            <v-subheader style="height: 2rem; opacity: 0.7">Serial Number</v-subheader>
                                            <v-subheader class="subheadContent" v-if="selectedUnit?.transportGensets[0]?.serialNumber">
                                                {{ selectedUnit.transportGensets[0].serialNumber }}
                                            </v-subheader>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="booking">
                            <v-card class="tabContent px-2 pb-2 pt-6" flat>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4 pb-3" >
                                    <v-subheader class="pl-2" style="font-size: 14px; height: 30px">
                                        <!-- <v-icon class="mr-2" small color="secondary"> -->
                                            <!-- person -->
                                        <!-- </v-icon> -->
                                            <b>Driver</b>
                                    </v-subheader>
                                    <v-divider></v-divider>
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-subheader style="height: 2rem; opacity: 0.7">Name</v-subheader>
                                            <v-subheader class="subheadContent" v-if="selectedUnit?.driver?.user?.firstname">
                                                {{ selectedUnit.driver.user.firstname }} {{ selectedUnit.driver.user.surname }}
                                            </v-subheader>
                                        </v-col>

                                        <v-col cols="12" sm="6" md="6">
                                            <v-subheader class="pr-2" style="height: 2rem; opacity: 0.7">Phone</v-subheader>
                                            <v-subheader class="subheadContent pr-2" style="height: 1.5rem" v-if="selectedUnit?.driver?.user?.mobileNumber">
                                                {{ formatNumber(selectedUnit.driver.user.mobileNumber) }}
                                            </v-subheader>
                                        </v-col>    
                                    </v-row>
                                </v-card>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="messages">
                            <v-card class="tabContent px-2 pb-2 pt-6" flat>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4 pb-3" >
                                </v-card>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="driver">
                            <v-card class="tabContent px-2 pb-2 pt-6" flat>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4 pb-3" >
                                </v-card>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item value="notifications">
                            <v-card class="tabContent px-2 pb-2 pt-6" flat>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4 pb-3" >
                                </v-card>
                            </v-card>
                        </v-tab-item>
                        
                        <v-tab-item value="trips">
                            <v-card class="tabContent px-2 pb-2 pt-6" flat>
                                <v-card style="height: auto; width: 100%; background-color: var(--v-background-lighten2) !important;" class="mb-4 pb-3" >
                                </v-card>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
                <!-- SECTION: FILTER CARD -->
                <v-card></v-card>
            </div>
        </div>

    </v-card>
</template>

<script>

export default {
    components: {},
    data: () => ({
        expandUnit: true,
        viewUnit: true,
        unitLabel: 'Vehicles',
        activeTab: 'unit',
        units: [],
        search: '',
        selectedUnit: null,
        filterCard: false,
        unitTabs: [
            {
                name: 'unit',
                icon: 'local_shipping',
            },
            {
                name: 'booking',
                icon: 'assignment',
            },
            {
                name: 'messages',
                icon: 'forum',
            },
            {
                name: 'driver',
                icon: 'person',
            },
            {
                name: 'notifications',
                icon: 'notifications',
            },
            {
                name: 'trips',
                icon: 'map',
            },
        ],

    }),
    mounted() {},
    
    async created() {
        await this.getTransportUnits();
    },
    
    computed: {
        unitList() {
            let unitList = []
            if(this.units.length > 0) {
                unitList = this.units.filter(unit => {
                    return  unit.driver.user.firstname.toLowerCase().includes(this.search.toLowerCase()) || 
                            unit.driver.user.surname.toLowerCase().includes(this.search.toLowerCase())
                })
            }
            return unitList
        },  
    },
    
    watch: {},
    
    methods: {
        selectUnit(unit) {
            this.selectedUnit = unit;
            this.viewUnit = true;
        },

        formatNumber(number) {
            let formatted = ''
            if(number) {
                if(number.charAt(0) === '0') {
                    formatted = `+27 ${number.substring(1, 3)} ${number.substring(3,6)} ${number.substring(6,10)}`;
                } else if (number.charAt(0) === '2') {
                    formatted = `+${number.substring(0, 2)} ${number.substring(2, 4)} ${number.substring(4,7)} ${number.substring(7,11)}`;
                } else {
                    formatted = number;
                }
            }
            return formatted
        },

        async getTransportUnits() {
            const result = await this.$API.getAvailableUnits();
            if(result) {
                console.log(result);
                this.units = result.map(unit => {
                    return {
                        ...unit,
                        driver: unit.transportDrivers[0] || null,
                    }
                });
                console.log('success')
            }
        },
    }
}
</script>


<style scoped>

.dark {
    background: var(--v-background-lighten1) !important
}

.light {
    background: var(--v-greyContrast-base) !important
}

.tabContent {
    height: calc(96vh - 44px - 10vh);
    background-color: transparent !important;

}

.infoCard {
    height: auto;
    width: 100%;
}
.backgroundMap {
    height: calc(96vh - 44px);
    width: 100%;
    background: url('./MAP.png') no-repeat fixed;
    background-size: cover;

}

.subheadContent {
    min-height: 1.5rem;
    height: auto;
    max-height: 3.5rem;
}


.tabItems, .tabItems ::v-deep div.v-window__container {
    background-color: transparent !important;
}

.tabBackground ::v-deep div.v-tabs-bar__content {
    background: var(--v-background-base) !important;
}
.activeTab {
    border-radius: 16px 16px 0 0 !important;
    width: 1rem;
    min-width: 1rem;
}

.leftInfo {
    display: flex;
    flex-direction: row;
}
.unitBox {
    height: 4vh;
    width: 13vw;
    background-color: var(--v-background-base) !important;
}
.unitList {
    height: calc(92vh - 44px);
    background-color: var(--v-background-base) !important;
    width: 13vw;
}

.unitTabs {
    /* height: 44px; */
    width: 100%;
}
.unitTab {
    background: var(--v-background-base) !important;
    border-radius: 0 !important;
    width: 1rem;
    min-width: 1rem;
}
.scrollList {
    height: calc(92vh - 44px - 1.5rem);
    overflow-y: scroll;
}
.unitInfo_light, .unitInfo_dark {
    height: calc(96vh - 44px);
    width: 22vw;
}
.unitInfo_light {
    background-color: var(--v-greyContrast-base) !important;
}

.unitInfo_dark {
    background-color: var(--v-background-lighten1) !important;
}

.unitItem {
    height: 4.5vh; 
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--v-greyContrast-base) !important;
}

.searchUnitList {
    height: 1.5rem;
    margin: 0 5px 0 5px;
    border-radius: 20px !important;
}

.searchUnitList ::v-deep div.v-input__control div.v-input__slot {
    min-height: auto !important;
    height: 1.5rem;
    font-size: small;
    display: flex !important;
    justify-items: center !important;
}
.searchUnitList ::v-deep div.v-input__append-inner {
    margin-top: 0.25rem !important;
}


</style>